:root {
  --color-white: #fff;
  --color-off-white: #f1fffa;
  --color-green-300: rgba(49, 72, 51, 0.4);
  --color-black: #0d0d0d;
  --color-gray: #464e47;
  --color-light-green: #ccfccb;
  --color-green: #58d89a;
  --color-dark-green: #44c888;

  --white: var(--color-off-white);
  --black: var(--color-black);
  --primary: var(--color-green);
  --secondary: var(--color-light-green);
  --tertiary: var(--color-dark-green);
  --quaternary: var(--color-gray);
  --background: var(--color-off-white);
  --overlay: var(--color-green-300);
  --radius: 5px;
  --gap: 4rem;
}

@font-face {
  font-family: 'Garet';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/GaretLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Garet';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/GaretBook.ttf') format('truetype');
}

@font-face {
  font-family: 'Garet';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/GaretRegular.ttf') format('truetype');
}

@font-face {
  font-family: 'Garet';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/GaretBold.ttf') format('truetype');
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Garet', sans-serif;
  color: var(--quaternary);
  background-color: var(--background);
  line-height: 1.4;
  overflow-x: hidden;
}

a {
  color: inherit;
}

button {
  background-color: transparent;
  color: white;
  cursor: pointer;
}

input,
textarea {
  background-color: transparent;
  font-family: inherit;
}

::placeholder {
  color: inherit;
  opacity: 0.7;
}

::-ms-input-placeholder {
  color: inherit;
}

section {
  width: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--overlay);
}

.parallax {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.messsageSentPage {
  width: 100%;
  margin-top: 5rem;
  min-height: calc(100vh - 5rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--primary);
  gap: var(--gap);
}

.messsageSentPage h1 {
  font-weight: 400;
  text-align: center;
}

.messsageSentPage a {
  width: fit-content;
  padding: 0.75rem 1.75rem;
  color: var(--white);
  background-color: var(--primary);
  border-radius: var(--radius);
  text-transform: uppercase;
}

.legalPage {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
  padding: 4rem 10%;
}

.legalPage p {
  margin-bottom: 2rem;
}

.legalPage h1 {
  font-weight: 400;
  text-align: center;
  margin-bottom: 4rem;
}

.legalPage h2 {
  margin-bottom: 0.75rem;
  font-weight: 400;
  color: var(--primary);
  font-size: 1.25rem;
  align-self: flex-start;
}
