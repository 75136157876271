.news {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 10%;
  gap: var(--gap);

  h2 {
    color: var(--tertiary);
    font-weight: 400;
    font-size: 2rem;
  }

  .more {
    width: fit-content;
    background-color: var(--tertiary);
    color: var(--white);
    padding: 0.75rem 1.75rem;
    text-transform: uppercase;
    border-radius: var(--radius);
  }

  .container {
    display: flex;
    gap: var(--gap);

    .newsContainer {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .image {
        height: 15rem;
        width: 100%;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .title {
        width: 95%;
        font-size: 1.15rem;
        font-weight: 700;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .news {
    padding: 4rem 5%;
    gap: 2rem;
    .container {
      flex-direction: column;
      gap: 2rem;
    }
  }
}
