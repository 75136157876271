nav {
  position: fixed;
  width: 100%;
  height: 5rem;
  display: flex;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  background-color: var(--tertiary);
  color: var(--white);
  z-index: 100;

  .announcementBar {
    width: 100%;
    background-color: var(--tertiary);
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 300;
  }

  .navbar {
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;

    .logo {
      color: var(--tertiary);
      height: 5rem;

      img {
        height: 100%;
        width: auto;
      }
    }

    .links {
      display: flex;
      gap: 0.75rem;
      text-transform: uppercase;
      font-size: 0.9rem;

      a {
        font-size: 0.9rem;
        width: fit-content;
        position: relative;
        padding-bottom: 0.15rem;

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 0.1rem;
          border-radius: 1rem;
          background-color: var(--white);
          left: 0;
          bottom: 0;
          transform: scale(0, 1);
          transform-origin: 0% 100%;
          transition: ease 300ms;
        }

        &:hover::after {
          transform: scale(1, 1);
        }
      }

      .active::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 0.1rem;
        border-radius: 1rem;
        background-color: var(--white);
        left: 0;
        bottom: 0;
        transform: scale(1, 1);
      }
    }

    .circle {
      display: none;
    }
  }

  .menuOverlay {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  nav {
    .navbar {
      .links {
        display: none;
      }

      .circle {
        background-color: transparent;
        padding: 0.6rem;
        // border-radius: 50%;
        aspect-ratio: 1/1;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;

        .menuBtn {
          height: 1rem;
          width: 1.5rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          cursor: pointer;

          .line {
            height: 2px;
            background-color: var(--white);
            border-radius: 1rem;
            transition: 200ms all ease;
            transition-delay: 0s !important;
          }

          &:hover {
            .line {
              width: 100% !important;
            }
          }
        }
      }
    }

    .menuOverlay {
      position: fixed;
      background-color: var(--tertiary);
      top: 0;
      right: -100vw;
      width: 100vw;
      overflow: hidden;
      transition: right 1s cubic-bezier(0.16, 1, 0.3, 1);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .backgroundImage {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 60%;
        opacity: 0.4;
        z-index: 1;

        img {
          height: 100%;
          width: 100%;
        }
      }

      .menuLinks {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 100vh;
        width: 100%;
        background-color: var(--tertiary);
        padding-left: 5%;

        .menuItem {
          margin: 0.25rem 0;
          position: relative;
          z-index: 2;


          a {
            color: var(--white);
            font-size: 2rem;
            font-weight: 300;
            position: relative;
            top: 0;
            transition: top 1s cubic-bezier(0.16, 1, 0.3, 1);
       

            &::after {
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              border-radius: 1rem;
              background-color: var(--white);
              left: 0;
              bottom: 0;
              transform: scale(0, 1);
              transform-origin: 0% 100%;
              transition: ease 300ms;
            }

            &:hover::after {
              transform: scale(1, 1);
            }
          }

          .menuItem-wrapper::after {
            content: '';
            position: absolute;
            top: 100px;
            left: -10%;
            width: 120%;
            height: 100%;
            background: var(--tertiary);
            margin: 0 auto;
          }
        }
      }
    }
  }
}
