.contact {
  width: 100%;
  padding: 6rem 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: var(--white);
  background-color: var(--tertiary);
  gap: 2rem;

  h2 {
    font-size: 2rem;
    font-weight: 400;
  }

  form {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .row {
      width: 100%;
      display: flex;
      gap: 2rem;
    }

    input,
    textarea {
      width: 100%;
      border-bottom: 1px solid var(--white);
      resize: none;
      color: var(--white);
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
    }

    button {
      width: fit-content;
      padding: 0.5rem 2rem;
      border: 1px solid var(--white);
      font-size: 1rem;
      letter-spacing: 1px;
    }
  }
}

@media screen and (max-width: 900px) {
  .contact {
    align-items: center;

    form {
      width: 90%;
    }
  }
}

@media screen and (max-width: 500px) {
  .contact {
    padding: 4rem 5%;

    h2 {
      font-size: 1.5rem;
    }

    form {
      .row {
        flex-direction: column;
      }
    }
  }
}
