.homePage {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5rem;

  header {
    position: relative;
    width: 100%;
    height: calc(100vh - 5rem);
    display: flex;
    align-items: center;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .content {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: var(--white);
      gap: 1rem;

      p {
        font-size: 2.5rem;
        font-weight: 500;
        letter-spacing: 2px;
        line-height: 1.2;
        width: 70%;
        text-align: center;
      }

      h4 {
        font-weight: 400;
        font-size: 1.25rem;
      }
    }
  }

  .quote {
    width: 100%;
    padding: 8rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: var(--tertiary);
    color: var(--white);

    p {
      width: 60%;
      font-size: 2.25rem;
    }

    h4 {
      font-weight: 400;
      font-size: 1.5rem;
    }
  }

  .mission {
    width: 100%;
    min-height: 30rem;
    display: flex;

    .container {
      flex: 1;
      padding: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      p {
        width: 70%;
        font-size: 1.15rem;
        color: var(--quaternary);
      }
    }
  }

  .services {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .service {
      display: flex;
      flex-direction: column;

      .content {
        aspect-ratio: 1/1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        text-align: center;
        color: var(--white);
        padding: 2rem;

        h3 {
          font-size: 1.5rem;
          line-height: 1.2;
        }

        p {
          font-size: 1.15rem;
        }

        a {
          padding: 0.75rem 1.5rem;
          background-color: var(--white);
          color: var(--quaternary);
          text-transform: uppercase;
          font-size: 0.85rem;
          font-weight: 700;
          border-radius: var(--radius);
        }
      }

      &:nth-child(1) {
        background-color: var(--primary);
      }
      &:nth-child(2) {
        background-color: var(--tertiary);
      }
      &:nth-child(3) {
        background-color: var(--primary);
      }

      .image {
        aspect-ratio: 1/1;

        img {
          aspect-ratio: 1/1;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .approach {
    width: 100%;
    padding: 6rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--tertiary);
    background-color: var(--white);

    p {
      width: 60%;
      font-size: 2rem;
    }
  }

  .about {
    width: 100%;
    display: flex;
    min-height: 25rem;
    background-color: var(--tertiary);
    color: var(--white);

    .content {
      flex: 5;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      p {
        width: 80%;
        text-align: center;
        font-size: 1.15rem;
      }
    }

    .image {
      flex: 4;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .reviews {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--gap);
    padding: 4rem 2.5rem;

    h2 {
      color: var(--tertiary);
    }

    .carousel {
      position: relative;
      width: 100%;
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--gap);
      // padding: 0 2.5rem;

      .nav {
        // position: absolute;
        // top: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        // transform: translateY(0);
        height: 100%;

        svg {
          font-size: 3rem;
          color: black;
        }
      }

      .carouselContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        // padding: 0 2rem;
        gap: 1rem;

        p {
          width: 90%;
          text-align: center;
          font-size: 0.9rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .homePage {
    header {
      .content {
        p {
          width: 90%;
          font-size: 2.25rem;
        }
      }
    }

    .quote {
      padding: 6rem 0;
      p {
        width: 80%;
        font-size: 2rem;
      }
    }

    .services {
      grid-template-columns: 1fr;

      .service {
        flex-direction: row;

        .content {
          flex: 1;
          justify-content: space-around;
          aspect-ratio: unset;
        }

        .image {
          flex: 1;
        }
      }
    }

    .approach {
      p {
        width: 80%;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .homePage {
    .mission {
      flex-direction: column;
      padding: 4rem 10%;
      gap: var(--gap);

      .container {
        padding: 0;

        p {
          width: 100%;
        }
      }
    }
    .services {
      .service {
        flex-direction: column;

        &:nth-child(2n + 1) {
          flex-direction: column-reverse;
        }

        .content {
          aspect-ratio: unset;
          h3 {
            font-size: 1.25rem;
          }

          p {
            font-size: 1rem;
          }
        }

        .image {
          height: 15rem;
          aspect-ratio: unset;
        }
      }
    }

    .approach {
      p {
        width: 90%;
        font-size: 1.5rem;
      }
    }

    .about {
      min-height: fit-content;
      padding: 2rem 0;
      .content {
        p {
          width: 90%;
          font-size: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .homePage {
    header {
      .content {
        p {
          width: 90%;
          font-size: 1.5rem;
        }

        h4 {
          font-size: 0.85rem;
        }
      }
    }
    .quote {
      padding: 4rem 0;
      p {
        width: 90%;
        font-size: 1.5rem;
      }
    }

    .approach {
      padding: 4rem 0;
      p {
        font-size: 1.25rem;
      }
    }

    .about {
      .content {
        p {
          width: 100%;
          font-size: 1rem;
        }
      }
    }
  }
}
