.faq {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 10%;

  h1 {
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 2rem;
    color: var(--tertiary);
  }

  .row {
    width: 80%;
    border-bottom: 1px solid var(--overlay);
  }
}

@media screen and (max-width: 1000px) {
  .faq {
    padding: 4rem 5% 2rem;
    .row {
      width: 100%;
    }
  }
}