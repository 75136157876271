footer {
  width: 100%;
  // height: 20rem;
  padding: 2rem 2rem 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .main {
    width: 100%;
    max-width: 1200px;
    padding: 4rem 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;

    .container {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      &:nth-child(2n) {
        a {
          font-size: 0.9rem;
          width: fit-content;
          position: relative;
          padding-bottom: 0.15rem;

          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 0.1rem;
            border-radius: 1rem;
            background-color: var(--tertiary);
            left: 0;
            bottom: 0;
            transform: scale(0, 1);
            transform-origin: 0% 100%;
            transition: ease 300ms;
          }

          &:hover::after {
            transform: scale(1, 1);
          }
        }
      }

      .logo {
        height: 5rem;

        img {
          height: 100%;
          width: auto;
        }
      }

      h3 {
        color: var(--tertiary);
        margin-bottom: 0.25rem;
        margin-left: -0.5rem;
      }
    }
  }

  .bottom {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    font-size: 0.85rem;

    p {
      display: flex;
      align-items: center;

      a {
        margin-left: 0.25rem;
      }
    }

    svg {
      font-size: 0.5rem;
      margin: 0 0.5rem;
    }

    .legal {
      display: flex;
      gap: 0.5rem;
    }
  }
}

@media screen and (max-width: 1200px) {
  footer {
    .main {
      padding: 4rem 5%;
    }
  }
}

@media screen and (max-width: 900px) {
  footer {
    .main {
      grid-template-columns: repeat(2, 1fr);

      .container {
        align-items: center;
        &:first-child {
          justify-content: center;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  footer {
    .bottom {
      flex-direction: column;
      .legal,
      p {
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  footer {
    .main {
      grid-template-columns: 1fr;
      .container {
        align-items: flex-start;
        &:first-child {
          align-items: center;
        }
      }
    }

    .bottom {
      flex-direction: column;

      p {
        display: flex;
        flex-direction: column;

        svg {
          display: none;
        }
      }
      .legal {
        justify-content: center;
      }
    }
  }
}
