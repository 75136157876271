.articlePage {
  width: 90%;
  margin: 7rem auto -2rem;
  display: flex;
  flex-direction: column;

  .articlePageHeader {
    display: flex;
    flex-direction: column;
    padding-top: 4rem;

    a {
      text-transform: uppercase;
      margin-bottom: 2rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: fit-content;

      svg {
        font-size: 0.75rem;
        margin-right: 0.5rem;
        transition: all 300ms ease;
      }

      &:hover {
        svg {
          color: var(--tertiary);
        }
      }
    }

    h1 {
      font-size: 4.5rem;
      line-height: 1.2;
      width: 85%;
      margin-bottom: 4rem;
      font-weight: 900;
    }

    .articleFeaturedImage {
      width: 100%;
      height: 30rem;
      align-self: flex-end;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .article {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 4rem;

    .articleInfo {
      display: flex;
      flex-direction: column;

      .infoRow {
        display: flex;
        font-size: 0.9rem;

        span {
          font-weight: 700;
          text-transform: uppercase;
        }

        p {
          margin-right: 0.5rem;
        }
      }
    }

    .articleContent {
      align-self: flex-end;
      width: 60%;
      padding-right: 8%;

      h2 {
        margin-bottom: 1rem;
        border-left: 3px solid var(--tertiary);
        line-height: 1.2;
        padding-left: 1rem;
      }

      h3 {
        font-weight: 600;
        margin-bottom: 0.5rem;
        color: var(--tertiary);
      }

      p {
        margin-bottom: 2rem;
        // text-align: justify;
      }

      ul {
        margin-bottom: 2rem;

        li {
          list-style: disc;
          margin-left: 1rem;
          padding-left: 0.5rem;
          margin-bottom: 0.5rem;
        }
      }

      img {
        height: 20rem;
        width: 100%;
        object-fit: cover;
        // margin-bottom: 2rem;
      }
    }
  }

  .articleFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-top: 2rem;
    gap: 4rem;

    .articleFooter-btn {
      // margin-top: 2rem;
      // background-color: var(--quaternary);
      // font-size: 1.2rem;
      // padding: 0.75rem 3rem;
      // color: var(--primary);
      // border-radius: 3rem;
      // transition: all ease 600ms;
      // display: flex;
      // align-items: center;
      // cursor: pointer;
      // width: fit-content;

      // &:hover {
      //   box-shadow: inset 12em 0 0 0 var(--secondary);
      // }
      margin-top: 2rem;
      width: fit-content;
      background-color: var(--tertiary);
      color: var(--white);
      padding: 0.75rem 1.75rem;
      text-transform: uppercase;
      border-radius: var(--radius);
    }

    h1 {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 2rem;
    }
  }
}

.horizontalLine {
  display: none;
}

// MEDIUM SCREENS
@media screen and (max-width: 1000px) {
  .articlePage {
    .articlePageHeader {
      h1 {
        font-size: 3.75rem;
        width: 100%;
        margin-bottom: 2rem;
      }
    }

    .article {
      flex-direction: column;
      padding-top: 2rem;

      .articleInfo {
        flex-direction: row;
        padding-bottom: 2rem;
      }

      .articleContent {
        width: 100%;
        padding-right: 0;
      }
    }
  }

  .horizontalLine {
    margin-right: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
  }
}

@media screen and (max-width: 700px) {
  .articlePage {
    .articlePageHeader {
      h1 {
        font-size: 2.75rem;
      }

      .articleFeaturedImage {
        height: 20rem;
      }
    }

    .article {
      flex-direction: column;
      padding-top: 2rem;

      .articleInfo {
        flex-direction: column;
        padding-bottom: 2rem;
      }

      .articleContent {
        width: 100%;
      }
    }
  }

  .horizontalLine {
    display: none;
  }
}

// SMALL SCREENS
@media screen and (max-width: 450px) {
  .articlePage {
    .articlePageHeader {
      padding-top: 0;

      h1 {
        font-size: 2rem;
      }

      .articleFeaturedImage {
        height: 15rem;
      }
    }

    .article {
      .articleContent {
        img {
          height: 15rem;
        }

        p {
          margin-bottom: 2rem;
        }
      }
    }
  }
}
