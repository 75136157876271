.newsPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  align-items: center;
  gap: var(--gap);
  padding: 0 10%;

  h1 {
    padding: 4rem 0 0;
    color: var(--tertiary);
    font-weight: 400;
    font-size: 2.5rem;
  }

  .container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--gap);

    .newsContainer {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .image {
        height: 15rem;
        width: 100%;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .title {
        width: 95%;
        font-size: 1.15rem;
        font-weight: 700;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .newsPage {
    padding: 0 5%;

    .container {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  }
}