.servicesPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;

  header {
    position: relative;
    width: 100%;
    height: 25rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      h1 {
        color: var(--white);
        text-align: center;
        width: 50%;
        font-weight: 400;
        z-index: 2;
      }
    }

    .backgroundImage {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .services {
    width: 100%;
    padding: 4rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    // gap: var(--gap);

    h1 {
      margin-bottom: 4rem;
      font-size: 2.25rem;
      color: var(--tertiary);
      font-weight: 400;
    }

    .container {
      width: 100%;
      display: flex;
      height: 22rem;
   

      .image {
        flex: 1;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem 0;
        gap: 1rem;

        p {
          width: 50%;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .servicesPage {
    header {
      .content {
        h1 {
          width: 80%;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .servicesPage {
    .services {
      padding: 2rem 0 0;

      h1 {
        margin-bottom: 2rem;
        font-size: 2rem;
      }
      .container {
        flex-direction: column;
        height: fit-content;

        &:nth-child(2n+1) {
          flex-direction: column-reverse;
        }

        .content {
          p{
            width: 90%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .servicesPage {
    header {
      .content {
        h1 {
          width: 90%;
          font-size: 1.75rem;
        }
      }
    }
  }
}