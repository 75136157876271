.firstStepPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;

  header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 25rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    h1 {
      position: absolute;
      font-weight: 400;
      color: var(--white);
      text-align: center;
      line-height: 1.2;
      font-size: 2.25rem;
    }
  }

  .quote {
    width: 100%;
    height: 25rem;
    background-color: var(--tertiary);
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      width: 60%;
      text-align: center;
      font-weight: 400;
      color: var(--white);
      font-size: 2rem;
    }
  }

  .reasons {
    width: 100%;
    display: flex;
    padding: 2rem 0;
    gap: var(--gap);

    .container {
      padding: 4rem 5%;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h1 {
        font-size: 1.75rem;
        font-weight: 400;
      }

      p {
        width: 90%;
        align-self: flex-start;
      }
    }
  }

  .request {
    width: 100%;
    background-color: var(--tertiary);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--gap);
    color: var(--white);
    padding: 4rem 5%;

    h1 {
      font-size: 2rem;
      font-weight: 400;
      letter-spacing: 1px;
      text-align: center;
    }

    .grid {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      align-items: center;
      justify-items: center;
      text-align: center;

      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;

        p {
          width: 90%;
        }
      }
    }

    a {
      width: fit-content;
      padding: 0.75rem 1.75rem;
      color: var(--quaternary);
      background-color: var(--white);
      font-size: 0.9rem;
    }
  }
}

@media screen and (max-width: 800px) {
  .firstStepPage {
    .quote {
      h1 {
        width: 90%;
      }
    }
    .reasons {
      flex-direction: column;
      gap: 2rem;
      padding: 4rem 0;

      .container {
        padding: 0 5%;

        p {
          width: 100%;
          text-align: center;
        }
      }
    }
    .request {
      gap: 2rem;

      .grid {
        grid-template-columns: 1fr;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .firstStepPage {
    header {
      h1 {
        font-size: 1.75rem;
      }
    }

    .quote {
      height: fit-content;
      padding: 4rem 0;

      h1 {
        width: 90%;
        font-size: 1.5rem;
      }
    }

    .request {
      h1 {
        font-size: 1.75rem;
      }
    }
  }
}
