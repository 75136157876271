.aboutUs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
  gap: var(--gap);

  header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 25rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    h1 {
      width: 60%;
      z-index: 1;
      position: absolute;
      font-weight: 400;
      color: var(--white);
      text-align: center;
      line-height: 1.2;
      font-size: 2.5rem;
    }
  }

  .about {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 5%;

    .imageDesktop {
      flex: 2;
      padding: 2rem;
      display: flex ;
      justify-content: center;

      img {
        width: 80%;
        height: auto;
        object-fit: cover;
      }
    }

    .imageMobile {
      display: none;
    }

    h1 {
      // flex: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 2.25rem;
      color: var(--tertiary);
      font-weight: 400;
    }

    .content {
      flex: 3;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      p {
        width: 100%;
        // text-align: center;
        font-size: 1.15rem;
      }
    }
  }

  .therapy {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--gap);
    padding: 0 5%;

    .title {
      display: flex;
      flex-direction: column;
      text-align: center;
      gap: 0.5rem;

      h2 {
        font-weight: 400;
        text-transform: uppercase;
        font-size: 1.25rem;
      }

      h1 {
        color: var(--tertiary);
        font-weight: 400;
        font-size: 2.25rem;
        line-height: 1.2;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      p {
        width: 70%;
        text-align: center;
        font-size: 1.15rem;
      }
    }
  }

  .ethics {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5%;
    gap: var(--gap);

    h1 {
      color: var(--tertiary);
      font-weight: 400;
      font-size: 2.25rem;
      text-align: center;
    }

    .content {
      display: flex;

      h3 {
        flex: 3;
        text-align: center;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .text {
        flex: 4;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          width: 80%;
          text-align: center;
          font-size: 1.25rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .aboutUs {
    header {
      h1 {
        width: 80%;
      }
    }
    .about {
      flex-direction: column;
      gap: 2rem;

      .imageDesktop {
        display: none;
      }

      .imageMobile {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        height: 25rem;

        img {
          width:auto;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .therapy {
      gap: 2rem;

      .content {
        p {
          width: 100%;
        }
      }
    }

    .ethics {
      .content {
        flex-direction: column;
        gap: 1rem;

        h3 {
          br {
            display: none;
          }
        }

        .text {
          p {
            width: 100%;
          }
        }

        &:nth-child(2n + 1) {
          flex-direction: column-reverse;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .aboutUs {
    p {
      font-size: 1rem !important;
    }
    header {
      h1 {
        width: 80%;
        font-size: 1.75rem;
      }
    }

    .about {
      .content {
        p {
          text-align: center;
        }
      }
    }
    .therapy,
    .ethics {
      gap: 2rem;

      .title {
        h2 {
          font-size: 1rem;
        }
      }
    }
  }
}
